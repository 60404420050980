import {
  request,
  requestForDownload,
  requestForUpload,
} from "../../api/request";

import mime from "mime";
import { IUser } from "../../types/type";

export interface UploadFileResult {
  fileName: string;
}

export interface UniversityDTO {
  name: string;
  image: string;
  qualificationCount: number;
  translations: { name: string; languageCode: string }[];
}

export interface SpecialityDTO {
  id: string;
  name: string;
  code: string;
  subGroup: string;
  totalPlaces: number;
  sector: string;
  isPaid: boolean;
  price: number;
  isRemote: boolean;
  universityId: string;
}

export interface SignUpRequestDTO {
  phoneNumber: string;
  password: string;
  otpCode: string;
  fullName: string;
}

export interface OTPRequestDTO {
  phoneNumber: string;
}

export interface NotificationDTO {
  title: string;
  body: string;
  user: IUser;
}
// CRUD university

export const createUniversity = async (data: UniversityDTO) => {
  try {
    const response = await request.post("/university/create", data);
    return response.data;
  } catch (error) {
    return false;
  }
};

export const getUniversities = async () => {
  try {
    const response = await request.get("/university/list");
    return response.data;
  } catch (error) {
    return false;
  }
};

export const getUniversity = async (id: string) => {
  try {
    const response = await request.get(`/university/get/${id}`);
    return response.data;
  } catch (error) {
    return false;
  }
};

export const updateUniversity = async (data: UniversityDTO) => {
  try {
    const response = await request.post("/university/update", data);
    return response.data;
  } catch (error) {
    return false;
  }
};

export const deleteUniversity = async (id: string) => {
  try {
    const response = await request.get(`/university/delete/${id}`);
    return response.data;
  } catch (error) {
    return false;
  }
};

export const getUniversitiesBySectorAndGroup = async (
  sector: string,
  groupId: string
) => {
  try {
    const response = await request.get(`/university/list/${sector}/${groupId}`);
    return response.data;
  } catch (error) {
    return false;
  }
};

export const getGroups = async () => {
  try {
    const response = await request.get(`/group/list`);
    return response.data;
  } catch (error) {
    return false;
  }
};

// CRUD speciality

export const createSpeciality = async (data: any) => {
  try {
    const response = await request.post("/speciality/create", data);
    return response.data;
  } catch (error) {
    return false;
  }
};

export const getSpecialities = async () => {
  try {
    const response = await request.get("/speciality/list");
    return response.data;
  } catch (error) {
    return false;
  }
};

export const getSpecialitiesByUniversity = async (universityId: string) => {
  try {
    const response = await request.get(
      `/speciality/specialitiesByUniversity/${universityId}`
    );
    return response.data;
  } catch (error) {
    return false;
  }
};

export const getSpeciality = async (id: string) => {
  try {
    const response = await request.get(`/speciality/get/${id}`);
    return response.data;
  } catch (error) {
    return false;
  }
};

export const updateSpeciality = async (data: any) => {
  try {
    const response = await request.post("/speciality/update", data);
    return response.data;
  } catch (error) {
    return false;
  }
};

export const deleteSpeciality = async (id: string) => {
  try {
    const response = await request.get(`/speciality/delete/${id}`);
    return response.data;
  } catch (error) {
    return false;
  }
};

// file upload and download

export const uploadFile = async (file: string, blob: Blob) => {
  try {
    const formData = new FormData();
    let ftype = mime.getType(file) || "application/ocretstream";
    let f = new File([blob], file, { type: ftype });
    formData.append("file", f);
    const response = await requestForUpload.post<UploadFileResult>(
      `/file/uploadFile`,
      formData
    );
    return response.data;
  } catch (error) {
    return false;
  }
};

export const downloadFile = async (file: string) => {
  try {
    const response = await requestForDownload.get<Blob>(
      `/file/getFile/${file}`
    );
    return response.data;
  } catch (error) {
    console.log("error", error);
    return null;
  }
};

export const getUsers = async () => {
  try {
    const response = await request.get("/user/list");
    return response.data;
  } catch (error) {
    return false;
  }
};

export const signUp = async (data: SignUpRequestDTO) => {
  try {
    const response = await request.post("/auth/register", data);
    return response.data;
  } catch (error) {
    return false;
  }
};

export const sendOTP = async (data: OTPRequestDTO) => {
  try {
    const response = await request.post("/auth/send-otp", data);
    return response.data;
  } catch (error) {
    return false;
  }
};

export const getApplications = async () => {
  try {
    const response = await request.get("/application/list");
    return response.data;
  } catch (error) {
    return false;
  }
}

export const sendNotification = async (data: NotificationDTO) => {
  try {
    const response = await request.post("/notification/send", data);
    return response.data;
  } catch (error) {
    return false;
  }
}

export const setUserCanChangePoints = async (canChange: boolean, userId: string) => {
  try {
    const response = await request.post(`/user/set-userCanChangePoint/${canChange}/${userId}`);
    return response.data;
  } catch (error) {
    return false;
  }
}

export const setAllUserCanChangePoints = async (canChange: boolean) => {
  try {
    const response = await request.post(`/user/set-allUserCanChangePoint/${canChange}`);
    return response.data;
  } catch (error) {
    return false;
  }
}