import React, { useEffect, useMemo, useState } from "react";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import {
  getUsers,
  sendNotification,
  setAllUserCanChangePoints,
  setUserCanChangePoints,
} from "../../store/actions/adminActions";
import Input from "../Input";
import Send from "../../assets/images/send.png";
import SelectList from "../SelectList";
import { canChangePoint } from "../../constants";
import { getLocalStorage, setLocalStorage } from "../../constants/localstorage";
import { verifyToken } from "../../store/actions/authActions";
import { Navigate } from "react-router-dom";
import DataTable, { TableColumn } from "react-data-table-component";
import { IUser } from "../../types/type";

const Users: React.FC = () => {
  const [users, setUsers] = useState<IUser[]>([]);
  const [notificationTitle, setNotificationTitle] = useState("");
  const [notificationBody, setNotificationBody] = useState("");
  const [changePoint, setChangePoint] = useState(false);
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const token = getLocalStorage("accessToken");
  const [currentPage, setCurrentPage] = useState(0);

  const ITEMS_PER_PAGE = 5;
  const page = useMemo(
    () => Math.ceil(users.length / ITEMS_PER_PAGE),
    [users.length]
  );

  useEffect(() => {
    if (token) {
      verifyToken()
        .then((isTokenValid) => {
          setIsLoggedIn(isTokenValid);
          setLocalStorage("isLoggedIn", isTokenValid);
          fetchUsers();
        })
        .catch((error) => {
          console.error("Token verification failed", error);
          setIsLoggedIn(false);
          setLocalStorage("isLoggedIn", "false");
        });
    } else {
      setIsLoggedIn(false);
      setLocalStorage("isLoggedIn", "false");
    }
  }, [token]);

  const localIsLoggedIn = getLocalStorage("isLoggedIn");
  if (!localIsLoggedIn || !token) return <Navigate to="/login" />;

  const fetchUsers = async () => {
    const users = await getUsers();
    setUsers(users);
  };

  const handleSendNotification = async (userId: string) => {
    if (!notificationTitle || !notificationBody) {
      toast.error("Notification title and body cannot be empty");
      return;
    }
    const user = users.find((user) => user.id === userId);
    if (!user) return;

    await sendNotification({
      title: notificationTitle,
      body: notificationBody,
      user: user,
    });

    toast.success(`Notification sent to user ${userId}`);
  };

  const handleSendNotificationToAll = async () => {
    if (!notificationTitle || !notificationBody) {
      toast.error("Notification title and body cannot be empty");
      return;
    }
    for (const user of users) {
      await sendNotification({
        title: notificationTitle,
        body: notificationBody,
        user: user,
      });
    }
    toast.success("Sent notification to all users");
  };

  const handleUserCanChangePoint = async (userId: string) => {
    const user = users.find((user) => user.id === userId);
    if (!user) return;
    const response = await setUserCanChangePoints(
      !user.userCanChangePoint,
      userId
    );
    fetchUsers();
    if (response) {
      toast.success(`User ${userId} can change points`);
    } else {
      toast.error(`User ${userId} cannot change points`);
    }
  };

  const handleAllUserCanChangePoint = async () => {
    const response = await setAllUserCanChangePoints(changePoint);
    fetchUsers();
    if (response) {
      toast.success(`All users can change points`);
    } else {
      toast.error(`All users cannot change points`);
    }
  };

  const columns: TableColumn<IUser>[] = [
    { name: "ID", selector: (row) => row?.id, sortable: true },
    { name: "Full Name", selector: (row) => row?.fullName, sortable: true },
    { name: "Telephone", selector: (row) => row?.phoneNumber, sortable: true },
    { name: "Password", selector: (row) => row?.password, sortable: true },
    {
      name: "Last Login Date",
      selector: (row) => row?.lastLoginDate,
      sortable: true,
    },
    { name: "Role", selector: (row) => row?.roles[0].name, sortable: true },
    {
      name: "Logged In",
      selector: (row) => (row?.loggedIn ? "Hə" : "Yox"),
      sortable: true,
    },
    {
      name: "Notification Token",
      selector: (row) => row?.notificationToken,
      sortable: true,
    },
    {
      name: "User Can Change Point",
      selector: (row) => (row?.userCanChangePoint ? "Hə" : "Yox"),
      sortable: true,
    },
    {
      name: "Actions",
      cell: (row) => (
        <div className="flex gap-2">
          <button
            onClick={() => handleSendNotification(row?.id)}
            className="w-16 h-12 rounded-1 bg-blue flex items-center justify-center"
          >
            <p className="text-h1 text-white">
              <img src={Send} alt="actionIcon" />
            </p>
          </button>
          <button
            onClick={() => handleUserCanChangePoint(row?.id)}
            className="w-16 h-12 rounded-1 bg-cyan flex items-center justify-center"
          >
            <p className="text-h1 text-white">Dəyiş</p>
          </button>
        </div>
      ),
    },
  ];

  return (
    <div>
      <ToastContainer />
      <div className="mb-4">
        <div className="w-full gap-[50px] items-center flex justify-start ">
          <Input
            label="Notification Title"
            placeholder="Enter the notification title"
            value={notificationTitle}
            onChange={(e) => setNotificationTitle(e.target.value)}
            className="mb-4"
          />
          <Input
            label="Notification Body"
            placeholder="Enter the notification body"
            value={notificationBody}
            onChange={(e) => setNotificationBody(e.target.value)}
            className="mb-4"
          />
          <button
            onClick={handleSendNotificationToAll}
            className="w-16 h-12 rounded-1 bg-blue flex items-center justify-center ml-3"
          >
            <p className="text-h1 text-white">
              <img src={Send} alt="actionIcon" />
            </p>
          </button>
        </div>
      </div>
      <div className="mb-4 flex items-end gap-[50px]">
        <SelectList
          label="Istifadəçi balını dəyişə bilər"
          value={changePoint ? "Hə" : "Yox"}
          onChange={(e) => setChangePoint(e.target.value === "Hə")}
          options={canChangePoint}
        />
        <button
          onClick={handleAllUserCanChangePoint}
          className="w-20 h-16 rounded-1 bg-cyan flex items-center justify-center ml-3"
        >
          <p className="text-h1 text-white">Hamısını dəyiş</p>
        </button>
      </div>
      <DataTable columns={columns} data={users} pagination />
    </div>
  );
};

export default Users;
