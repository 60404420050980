export enum MenuNames {
  university = "Universitetlər",
  faculty = "Fakultələr",
  speciality = "İxtisaslar",
}

export interface IFormData {
  name: string;
  code: string;
  totalPlaces: number;
  paidPlace: number;
  unPaidPlace: number;
  sector: string;
  subGroup: string | null;
  price: number;
  isRemote: boolean;
  languageCode: string;
  translationName: string;
  university: string;
  group: string;
  passPoint: number;
}

export interface IGroup {
  id: string;
  name: string;
  translations: { name: string; languageCode: string }[];
}

export interface IUniversity {
  id: string;
  image: string;
  name: string;
  qualificationCount: number;
  translations: { name: string; languageCode: string }[];
}

export interface ISpeciality {
  id: string;
  name: string;
  code: string;
  emptyPlace: number;
  fullPlace: number;
  totalPlace: number;
  paidPlaces: number;
  unPaidPlaces: number;
  emptyPaidPlaces: number;
  emptyUnPaidPlaces: number;
  fullPaidPlaces: number;
  fullUnPaidPlaces: number;
  sector: string;
  subGroup: string;
  price: number;
  isRemote: boolean;
  translations: { name: string; languageCode: string }[];
  university: IUniversity;
  group: IGroup;
  passPoint: number;
}

export interface IRole {
  id: string;
  name: string;
}

export interface IUser {
  id: string;
  fullName: string;
  phoneNumber: string;
  roles: IRole[];
  password: string;
  otp: string;
  userCanChangePoint: boolean;
  loggedIn: boolean;
  notificationToken: string;
  lastLoginDate: string;
}

export interface IApplication {
  id: string;
  order_id: string;
  amount: number;
  transaction: string;
  payed: boolean;
  paymantConfirmed: boolean;
  accepted: boolean;
  point: number;
  sector: string;
  specialityProbabilities: {
    speciality: ISpeciality;
    probability: number;
    paid: boolean;
  }[];
  group: IGroup;
  user: IUser;
  probability: number;
  createDate: string;
}