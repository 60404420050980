import { Navigate, useLocation } from "react-router-dom";
import Dashboard from "../../screen/Dashboard";
import LinkButton from "../LinkButton";
import { removeLocalStorage } from "../../constants/localstorage";
import { logout } from "../../store/actions/authActions";
const Layout = () => {
  let { pathname } = useLocation();

  const handleLogout = async () => {
    await logout();
    removeLocalStorage("accessToken");
    removeLocalStorage("refreshToken");
    removeLocalStorage("isLoggedIn");
    <Navigate to="/login" />;
  };

  return (
    <div className="flex w-screen h-screen px-10 pt-12 pb-16 gap-5">
      {/* Menu */}
      <div className="w-2/10 h-10/12 border-2 border-spacing-8 rounded-4xl border-purple px-6 py-5 flex flex-col items-center">
        <LinkButton
          active={pathname === "/university"}
          to={"university"}
          title="Universitetler"
        />
        <LinkButton
          active={pathname === "/speciality"}
          to={"speciality"}
          title="İxtisaslar"
        />
        <LinkButton
          active={pathname === "/listofusers"}
          to={"listofusers"}
          title="İstifadəçilər"
        />
        <LinkButton
          active={pathname === "/applications"}
          to={"applications"}
          title="Müraciətlər"
        />
        <button onClick={handleLogout} title="Çıxış et"/>
      </div>

      {/* Page */}
      <div className="w-8/10 py-2">
        <Dashboard />
      </div>
    </div>
  );
};

export default Layout;
