import React, { useEffect, useMemo, useRef, useState } from "react";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Input from "../Input";
import SelectList from "../SelectList";
import {
  createUniversity,
  getUniversities,
  deleteUniversity,
  updateUniversity,
} from "../../store/actions/adminActions";
import { languageCode } from "../../constants";
import { requestForUpload } from "../../api/request";
import { IUniversity } from "../../types/type";
import { getLocalStorage, setLocalStorage } from "../../constants/localstorage";
import { verifyToken } from "../../store/actions/authActions";
import { Navigate } from "react-router-dom";
import DataTable, { TableColumn } from "react-data-table-component";
import { Button, Modal, Form, Row, Col } from "react-bootstrap";
import Pen from "../../assets/images/pen.png";
import Trash from "../../assets/images/trash.png";
import Add from "../../assets/images/add.png";
import Close from "../../assets/images/close.png";

const University = () => {
  const [universityData, setUniversityData] = useState<IUniversity[]>([]);
  const defaultValue = useMemo(
    () => ({
      university: "",
      image: "",
      qualificationCount: 0,
      languageCode: "",
      translationName: "",
    }),
    []
  );
  const [formData, setFormData] = useState(defaultValue);
  const [editId, setEditId] = useState<string | null>(null);
  const disabled = useMemo(() => !formData?.university, [formData]);
  const fileInputRef = useRef<any>(null);

  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const token = getLocalStorage("accessToken");

  const [showModal, setShowModal] = useState(false);

  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [deleteId, setDeleteId] = useState<string | null>(null);

  const fetchUniversities = async () => {
    try {
      const universities = await getUniversities();
      if (universities) {
        setUniversityData(universities);
      }
    } catch (error) {
      toast.error("Failed to fetch universities");
      console.log(error);
    }
  };

  // setUniversityData on useEffect
  useEffect(() => {
    if (token) {
      verifyToken()
        .then((isTokenValid) => {
          setIsLoggedIn(isTokenValid);
          setLocalStorage("isLoggedIn", isTokenValid);
          fetchUniversities();
        })
        .catch((error) => {
          console.error("Token verification failed", error);
          setIsLoggedIn(false);
          setLocalStorage("isLoggedIn", "false");
        });
    } else {
      setIsLoggedIn(false);
      setLocalStorage("isLoggedIn", "false");
    }
  }, [token]);

  const localIsLogedIn = getLocalStorage("isLoggedIn");

  if (!localIsLogedIn || !token) return <Navigate to="/login" />;

  const handleImageUpload = async (file: File) => {
    const form = new FormData();
    form.append("file", file);
    try {
      const response = await requestForUpload.post(
        "/university/setUniPhoto",
        form
      );
      if (response.data) {
        const imageUrl = `https://app.studentall.com/unvlogos/${file.name}.png`;
        return imageUrl;
      }
      return "";
    } catch (error) {
      toast.error("Image upload failed");
      console.error("Image upload failed", error);
      return "";
    }
  };

  const addUniversity = async () => {
    try {
      const translations = [
        {
          name: formData?.translationName,
          languageCode: "ru",
        },
      ];

      const response = await createUniversity({
        name: formData?.university,
        image: formData?.image,
        qualificationCount: formData?.qualificationCount,
        translations: translations,
      });

      if (response) {
        setUniversityData([...universityData, response]);
        setFormData(defaultValue);
        if (fileInputRef.current) {
          fileInputRef.current.value = "";
        }
        toast.success("University added successfully");
        setShowModal(false); // Close modal after adding
      } else {
        toast.error("Failed to create university");
      }
    } catch (error) {
      toast.error("Failed to create university");
      console.log(error);
    }
  };

  const confirmDeleteUni = (id: string) => {
    setDeleteId(id);
    setShowDeleteModal(true);
  };

  const deleteUni = async () => {
    if (deleteId) {
      try {
        const response = await deleteUniversity(deleteId);
        if (response) {
          fetchUniversities();
          toast.success("University deleted successfully");
          setShowDeleteModal(false);
          setDeleteId(null);
        }
      } catch (error) {
        toast.error("Failed to delete university");
        console.log(error);
      }
    }
  };

  const editUniversity = async () => {
    try {
      const editedUniversity = {
        id: editId!.toString(),
        name: formData?.university,
        image: formData?.image,
        qualificationCount: formData?.qualificationCount,
        translations: [
          {
            name: formData?.translationName,
            languageCode: formData?.languageCode,
          },
        ],
      };
      const response = await updateUniversity(editedUniversity);
      if (response) {
        fetchUniversities();
        setEditId(null);
        setFormData(defaultValue);
        toast.success("University updated successfully");
        setShowModal(false); // Close modal after editing
      }
    } catch (error) {
      toast.error("Failed to update university");
      console.log(error);
    }
  };

  const columns: TableColumn<IUniversity>[] = [
    { name: "ID", selector: (row) => row?.id, sortable: true },
    { name: "Universitet", selector: (row) => row?.name, sortable: true },
    {
      name: "Şəkil",
      cell: (row) => (
        <img
          src={row?.image}
          alt={row?.name}
          className="w-16 h-16 object-cover"
        />
      ),
      sortable: true,
    },
    {
      name: "Tərcümə",
      selector: (row) => row?.translations[0]?.name,
      sortable: true,
    },
    {
      name: "Vakant yerler",
      selector: (row) => row?.qualificationCount,
      sortable: true,
    },
    {
      name: "Əməliyyatlar",
      cell: (row) => (
        <div className="flex justify-center">
          <Button
            onClick={() => {
              const editedItem = universityData.find(
                (item) => item?.id === row?.id
              );
              if (editedItem) {
                setEditId(row?.id);
                setFormData({
                  university: editedItem?.name,
                  image: editedItem?.image,
                  qualificationCount: editedItem?.qualificationCount,
                  languageCode: editedItem?.translations[0].languageCode,
                  translationName: editedItem?.translations[0].name,
                });
                setShowModal(true);
              }
            }}
            className="w-16 h-12 rounded-1 bg-cyan flex items-center justify-center ml-3"
          >
            <img width={20} height={20} src={Pen} alt="edit" />
          </Button>
          <Button
            onClick={() => confirmDeleteUni(row?.id)}
            className="w-16 h-12 rounded-1 bg-red flex items-center justify-center ml-3"
          >
            <img src={Trash} alt="delete" />
          </Button>
        </div>
      ),
    },
  ];

  return (
    <div>
      <ToastContainer />
      <Button
        variant="success"
        onClick={() => {
          setFormData(defaultValue);
          setEditId(null);
          setShowModal(true);
        }}
        className="w-16 h-12 rounded-1 bg-green flex items-center justify-center ml-3"
      >
        <img src={Add} alt="add" />
      </Button>
      <DataTable columns={columns} data={universityData} pagination />

      <Modal
        show={showModal}
        onHide={() => setShowModal(false)}
        size="lg"
        centered
        className="fixed inset-0 z-50 flex items-center justify-center mt-5"
      >
        <div className="bg-white rounded-lg shadow-lg max-w-lg w-full mx-auto my-auto relative">
          <button
            type="button"
            className="absolute top-2 right-2 text-black text-lg font-bold"
            onClick={() => setShowModal(false)}
          >
            <img src={Close} alt="close" />
          </button>
          <div className="bg-blue-500 text-white px-4 py-2 flex justify-between items-center rounded-t-lg">
            <h2 className="font-semibold text-lg">
              {editId ? "Redaktə et" : "Əlavə et"}
            </h2>
          </div>
          <div className="p-4">
            <Form>
              <div className="mb-4">
                <Input
                  label="Universitet"
                  value={formData?.university}
                  className="block w-full"
                  inputClassName="border rounded px-2 py-1 w-full"
                  onChange={(e) => {
                    setFormData((prev) => ({
                      ...prev,
                      university: e.target.value,
                    }));
                  }}
                />
              </div>
              <div className="mb-4">
                <Input
                  label="Qəbul sayı"
                  value={formData?.qualificationCount}
                  className="block w-full"
                  inputClassName="border rounded px-2 py-1 w-full"
                  onChange={(e) => {
                    setFormData((prev) => ({
                      ...prev,
                      qualificationCount: Number(e.target.value),
                    }));
                  }}
                />
              </div>
              <div className="mb-4">
                <SelectList
                  label="Tərcümənin dili"
                  value={formData?.languageCode}
                  onChange={(e) => {
                    setFormData((prev) => ({
                      ...prev,
                      languageCode: e.target.value,
                    }));
                  }}
                  options={languageCode}
                  className="border rounded px-2 py-1 w-full"
                />
              </div>
              <div className="mb-4">
                <Input
                  label="Tərcümə"
                  value={formData?.translationName}
                  className="block w-full"
                  inputClassName="border rounded px-2 py-1 w-full"
                  onChange={(e) => {
                    setFormData((prev) => ({
                      ...prev,
                      translationName: e.target.value,
                    }));
                  }}
                />
              </div>
            </Form>
          </div>
          <div className="bg-gray px-4 py-2 flex justify-end rounded-b-lg">
            <Button
              variant="secondary"
              onClick={() => setShowModal(false)}
              className="bg-red text-white px-4 py-2 rounded mr-2"
            >
              Bağla
            </Button>
            <Button
              variant="primary"
              onClick={editId ? editUniversity : addUniversity}
              disabled={disabled}
              className="bg-cyan text-white px-4 py-2 rounded"
            >
              {editId ? "Redaktə et" : "Əlavə et"}
            </Button>
          </div>
        </div>
      </Modal>
      <Modal
        show={showDeleteModal}
        onHide={() => setShowDeleteModal(false)}
        size="sm"
        centered
        className="fixed inset-0 z-50 flex items-center justify-center mt-10"
      >
        <div className="bg-white rounded-lg shadow-lg max-w-sm w-full mx-auto my-auto relative">
          <div className="p-4">
            <h2 className="font-semibold text-lg">Əminsinizmi?</h2>
            <p>Bu universiteti silmək istəyirsinizmi?</p>
          </div>
          <div className="bg-gray-100 px-4 py-2 flex justify-end rounded-b-lg">
            <Button
              variant="secondary"
              onClick={() => setShowDeleteModal(false)}
              className="bg-cyan text-white px-4 py-2 rounded mr-2"
            >
              Bağla
            </Button>
            <Button
              variant="danger"
              onClick={deleteUni}
              className="bg-red text-white px-4 py-2 rounded"
            >
              Sil
            </Button>
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default University;
