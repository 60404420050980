export const setLocalStorage = (name: string, value: string) => {
  localStorage.setItem(name, value);
};

export const removeLocalStorage = (name: string) => {
  localStorage.removeItem(name);
};

export const getLocalStorage = (name: string) => {
  return localStorage.getItem(name);
};

export const getAccessToken = async () => {
  return localStorage.getItem("accessToken");
};

export const getRefreshToken = async () => {
  return localStorage.getItem("refreshToken");
};
