import { Link, Navigate } from "react-router-dom";
import { useEffect, useState } from "react";
import { verifyToken } from "../../store/actions/authActions";
import { getLocalStorage, setLocalStorage } from "../../constants/localstorage";
import LoginForm from "./LoginForm";

const Login = () => {
  const [isLoggedIn, setIsLoggedIn] = useState(false);

  const token = getLocalStorage("accessToken");

  useEffect(() => {
    if (token) {
      // setIsLoggedIn(true);
      verifyToken()
        .then((isTokenValid) => {
          setIsLoggedIn(isTokenValid);
          setLocalStorage("isLoggedIn", isTokenValid.toString());
        })
        .catch((error) => {
          console.error("Token verification failed", error);
          setIsLoggedIn(false);
          setLocalStorage("isLoggedIn", "false");
        });
    } else {
      setIsLoggedIn(false);
      setLocalStorage("isLoggedIn", "false");
    }
  }, [token]);

  if (isLoggedIn) return <Navigate to="/" />;

  return (
    <div className="flex max-md:flex-col h-screen bg-cyan">
      <div className="w-1/2 max-md:w-full max-md:h-full bg-blue-700 text-black flex flex-col justify-center items-center px-20 max-md:px-5">
        <p className="text-xl font-bold mb-2 text-center">Xoş gəlmisiniz</p>
        <LoginForm />
      </div>
    </div>
  );
};

export default Login;
