import React, { InputHTMLAttributes, forwardRef } from "react";

interface CustomInputProps extends InputHTMLAttributes<HTMLInputElement> {
  label?: string;
  error?: string | boolean;
  leftContent?: React.ReactNode;
  rightContent?: React.ReactNode;
  inputClassName?: string;
}

const Input: React.ForwardRefRenderFunction<
  HTMLInputElement,
  CustomInputProps
> = (
  {
    label,
    placeholder,
    className,
    error,
    leftContent,
    rightContent,
    inputClassName,
    ...props
  },
  ref
) => {
  return (
    <div className={`relative ${className || ""}`}>
      {label && (
        <label className="block text-h4Bold mb-2" htmlFor={props.id}>
          {label}
        </label>
      )}
      <div className="flex items-center">
        {leftContent && (
          <div className="mr-2 absolute left-3">{leftContent}</div>
        )}
        <input
          ref={ref}
          className={`h-12 border border-0.5 border-lightGray2 rounded-2 w-full ${
            leftContent && "pl-9"
          } ${inputClassName ? inputClassName : "p-4"}`}
          placeholder={placeholder}
          {...props}
        />
        {rightContent && (
          <div className="ml-2 absolute right-3 bottom-1.5">{rightContent}</div>
        )}
      </div>
      <div className="absolute right-2 mt-1">
        <p className="text-h9 text-red">{error}</p>
      </div>
    </div>
  );
};

export default forwardRef(Input);
