import env from "react-dotenv";
import axios from "axios";
import {
  getAccessToken,
  getRefreshToken,
  removeLocalStorage,
  setLocalStorage,
} from "../constants/localstorage";

const BASE_URL = env.BASE_URL;

const API_KEY = env.API_KEY;

export const request = axios.create({
  baseURL: BASE_URL,
  responseType: "json",
  headers: {
    "Content-Type": "application/json;charset=UTF-8",
    "X-API-KEY": API_KEY,
  },
});

request.interceptors.request.use(
  async (config) => {
    config.headers["X-API-KEY"] = API_KEY;
    config.headers["Access-Control-Allow-Origin"] = "*";
    const token = await getAccessToken();
    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }
    return config;
  },
  (error) => Promise.reject(error)
);

export const requestForDownload = axios.create({
  baseURL: BASE_URL,
  responseType: "blob",
  headers: {
    "X-API-KEY": API_KEY,
  },
});

requestForDownload.interceptors.request.use(
  async (config) => {
    config.headers["X-API-KEY"] = API_KEY;
    config.headers["Access-Control-Allow-Origin"] = "*";
    const token = await getAccessToken();
    if (token) {
      config.headers["Authorization"] = `Bearer ${token}`;
    }
    return config;
  },
  (error) => Promise.reject(error)
);

export const requestForUpload = axios.create({
  baseURL: BASE_URL,
  responseType: "json",
  headers: {
    "X-API-KEY": API_KEY,
    "Content-Type": "multipart/form-data",
  },
});

requestForUpload.interceptors.request.use(
  async (config) => {
    config.headers["X-API-KEY"] = API_KEY;
    config.headers["Access-Control-Allow-Origin"] = "*";
    const token = await getAccessToken();
    if (token) {
      config.headers["Authorization"] = `Bearer ${token}`;
    }
    return config;
  },
  (error) => Promise.reject(error)
);

const refreshAccessToken = async () => {
  const refreshToken = getRefreshToken();
  if (!refreshToken) {
    return null;
  }

  try {
    const res = await axios.post(`${BASE_URL}/auth/refresh-token`, {
      refreshToken,
    });
    return res.data.accessToken;
  } catch (error) {
    return null;
  }
};

request.interceptors.response.use(
  async (res) => res,
  async (err) => {
    const originalConfig = err.config;

    if (
      originalConfig &&
      originalConfig.url !== "/auth/login" &&
      err.response
    ) {
      if (err.response.status === 401) {
        // LOGOUT OR REFRESH TOKEN CASE

        try {
          const newToken = await refreshAccessToken();
          if (newToken) {
            setLocalStorage("accessToken", newToken);
            originalConfig.headers["Authorization"] = `Bearer ${newToken}`;
            originalConfig.headers["X-API-KEY"] = API_KEY;
            return request(originalConfig);
          }
        } catch (refreshError) {
          // LOGOUT
          removeLocalStorage("accessToken");
          removeLocalStorage("refreshToken");
          window.location.replace("/login");

          return Promise.reject(refreshError);
        }
      }
    }

    return Promise.reject(err);
  }
);

export default request;
