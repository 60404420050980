import React from "react";
import { Link } from "react-router-dom";

interface IParams {
  to: string;
  active?: boolean;
  title: string;
}
const LinkButton = ({ to, active, title }: IParams) => {
  return (
    <Link
      to={to}
      className={
        active
          ? "bg-gradient-to-r from-purple via-purple to-cyan w-full h-12 rounded-3 flex items-center p-2 mt-6"
          : "w-full h-12 border border-darkGray rounded-3 flex items-center p-2 mt-6"
      }
    >
      <p
        className={
          active ? "ml-2 text-h4 text-white " : "ml-2 text-h4 text-black"
        }
      >
        {title}
      </p>
    </Link>
  );
};

export default LinkButton;
