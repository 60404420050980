import { request } from "../../api/request";
import { removeLocalStorage } from "../../constants/localstorage";

export interface SignUpRequestDTO {
  phoneNumber: string;
  password: string;
  otpCode: string;
  name: string;
}

export interface SignInRequestDTO {
  phoneNumber: string;
  password: string;
}

export interface OTPRequestDTO {
  phoneNumber: string;
  otpCode: number;
}

export const signIn = async (data: SignInRequestDTO) => {
  try {
    const response = await request.post("/auth/login", data);
    return response.data;
  } catch (error) {
    return false;
  }
};

const signUp = async (data: SignUpRequestDTO) => {
  try {
    const response = await request.post("/auth/register", data);
    return response.data;
  } catch (error) {
    return false;
  }
};

const sendOTP = async (data: OTPRequestDTO) => {
  try {
    const response = await request.post("/auth/send-otp", data);
    return response.data;
  } catch (error) {
    return false;
  }
};

const resetPassword = async (data: OTPRequestDTO) => {
  try {
    const response = await request.post("/auth/reset-password", data);
    return response.data;
  } catch (error) {
    return false;
  }
};

export const verifyToken = async () => {
  try {
    const response = await request.get("/auth/verify-token");
    return response.data;
  } catch (error) {
    console.log(error)
    return false;
  }
};

export const logout = async () => {
  try {
    const response = await request.post("/auth/logout");
    removeLocalStorage("accessToken");
    removeLocalStorage("refreshToken");
    return response.data;
  } catch (error) {
    return false;
  }
};