import React, { useEffect, useState } from "react";
import SelectList from "../SelectList";
import { useNavigate } from "react-router-dom";
import {
  getSpecialitiesByUniversity,
  getUniversities,
} from "../../store/actions/adminActions";
import { ISpeciality, IUniversity } from "../../types/type";

interface IFilterData {
  university: IUniversity | null;
  specialities: ISpeciality[] | null;
}

interface SpecialityFilterProps {
  onFilterChange: (filteredData: ISpeciality[]) => void;
}

const SpecialityFilter: React.FC<SpecialityFilterProps> = ({
  onFilterChange,
}) => {
  const [universityData, setUniversityData] = useState<IUniversity[]>([]);
  const [specialityData, setSpecialityData] = useState<ISpeciality[]>([]);
  const [selectedUniversity, setSelectedUniversity] =
    useState<IUniversity | null>(null);
  const [selectedSpeciality, setSelectedSpeciality] = useState(null);

  const [filterData, setFilterData] = useState<IFilterData>({
    university: null,
    specialities: null,
  });

  const navigate = useNavigate();

  useEffect(() => {
    fetchUniversities();
  }, []);

  useEffect(() => {
    if (selectedUniversity) {
      fetchSpecialities(selectedUniversity.id);
    }
  }, [selectedUniversity]);

  const fetchUniversities = async () => {
    try {
      const universities = await getUniversities();
      if (universities) {
        setUniversityData(universities);
        setSelectedUniversity(universities[0]);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const fetchSpecialities = async (universitetId: string) => {
    try {
      const specialities = await getSpecialitiesByUniversity(universitetId);
      if (specialities) {
        setSpecialityData(specialities);
        onFilterChange(specialities);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleUniversityChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const selectedUniversityId = e.target.selectedOptions[0].dataset.id!;
    const university =
      universityData.find((u) => u.id === selectedUniversityId) || null;
    setSelectedUniversity(university);
  };

  // useEffect(() => {
  //   const params = new URLSearchParams();
  //   params.append("university", filterData.university?.name ?? '');
  //   if (filterData.specialities) {
  //     params.append("speciality", filterData.specialities.map(s => s.name).join(","));
  //   }
  //   navigate(`?${params.toString()}`);
  // }, [filterData, navigate]);

  return (
    <div>
      <p className={"text-h4 mb-2"}>Filter</p>
      <div className="flex">
        <SelectList
          className="w-64 mr-4"
          label="Universitet"
          onChange={handleUniversityChange}
          options={universityData}
        />
        <SelectList
          className="w-64"
          label="Ixtisas"
          // onChange={(e) => {
          //   const id = +e.target.selectedOptions[0].dataset.id!;
          //   let copyFilter = { ...filterData };

          //   copyFilter.speciality = {
          //     id: id,
          //     name: e.target.value,
          //     universityId: copyFilter.university.id,
          //   };
          //   setFilterData(copyFilter);
          // }}
          options={specialityData}
        />
      </div>
    </div>
  );
};

export default SpecialityFilter;
