import { useNavigate } from "react-router-dom";
import { useState } from "react";
import { signIn } from "../../store/actions/authActions";
import { setLocalStorage } from "../../constants/localstorage";
import Input from "../Input";
import PhoneNumber from "./PhoneNumber";

const LoginForm = () => {
  const [phoneNumber, setPhoneNumber] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState("");
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);

  const onSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    setLoading(true);
    if (!phoneNumber) {
      setError("Mobil nömrə doldurulmalıdır");
      return;
    }
    if (!password) {
      setError("Şifrə doldurulmalıdır");
      return;
    }

    setError("");
    const number = "994" + phoneNumber;
    try {
      const response = await signIn({ phoneNumber: number, password });
      if (response) {
        setLoading(false);
        // setLocalStorage("userProfile", response);
        setLocalStorage("accessToken", response.accessToken);
        setLocalStorage("refreshToken", response.refreshToken);
        setLocalStorage("isLoggedIn", "true");
        navigate("/");
      } else {
        setLoading(false);
        setError("Mobil nömrə və ya şifrə yanlışdır!");
      }
    } catch (error) {
      setLoading(false);
      setError("Xəta baş verdi");
      console.error("Error while logging in:", error);
    }
  };
  const handlePhoneChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setPhoneNumber(event.target.value);
  };
  return (
    <form onSubmit={onSubmit} className="w-full flex flex-col gap-2 mt-8">
      <label htmlFor="">
        {"Mobil nömrə"}
        <PhoneNumber
          value={phoneNumber}
          onChange={(e) => handlePhoneChange(e)}
        />
      </label>
      <Input
        onChange={(e) => setPassword(e.target.value)}
        type="password"
        id="password"
        label={"Password"}
        name="password"
        placeholder="Şifrə"
      />
      <button
        type="submit"
        className="w-full h-14 rounded-md bg-white mt-6 flex items-center justify-center gap-4 duration-150"
      >
        <p className="text-blue-500 duration-200">Giriş et</p>
      </button>
      {error && <p className="text-red-500">{error}</p>}
    </form>
  );
};

export default LoginForm;
