import React, { useEffect, useMemo, useState } from "react";
import { IApplication, ISpeciality, IUniversity } from "../../types/type";
import {
  getApplications,
  getSpecialitiesByUniversity,
  getUniversities,
} from "../../store/actions/adminActions";
import Table from "../Table";
import { getLocalStorage, setLocalStorage } from "../../constants/localstorage";
import { verifyToken } from "../../store/actions/authActions";
import { Navigate } from "react-router-dom";
import DataTable, { TableColumn } from "react-data-table-component";

const Applications: React.FC = () => {
  const [applications, setApplications] = useState<IApplication[]>([]);
  const [specialities, setSpecialities] = useState<ISpeciality[]>([]);
  const [universities, setUniversities] = useState<IUniversity[]>([]);
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const token = getLocalStorage("accessToken");

  useEffect(() => {
    if (token) {
      verifyToken()
        .then((isTokenValid) => {
          setIsLoggedIn(isTokenValid);
          setLocalStorage("isLoggedIn", isTokenValid);
          fetchUniversities();
          fetchApplications();
        })
        .catch((error) => {
          console.error("Token verification failed", error);
          setIsLoggedIn(false);
          setLocalStorage("isLoggedIn", "false");
        });
    } else {
      setIsLoggedIn(false);
      setLocalStorage("isLoggedIn", "false");
    }
  }, [token]);

  const localIsLogedIn = getLocalStorage("isLoggedIn");
  if (!localIsLogedIn || !token) return <Navigate to="/login" />;

  const fetchApplications = async () => {
    const applications = await getApplications();
    setApplications(applications);
  };

  const fetchSpecialities = async (universityId: string) => {
    const specialities = await getSpecialitiesByUniversity(universityId);
    setSpecialities(specialities);
  };

  const fetchUniversities = async () => {
    const universities = await getUniversities();
    setUniversities(universities);
  };

  const handleEditApplication = (id: string) => {
    console.log(id);
  };

  const columns: TableColumn<IApplication>[] = [
    { name: "ID", selector: (row) => row?.id, sortable: true },
    { name: "Order ID", selector: (row) => row?.order_id, sortable: true },
    { name: "Amount", selector: (row) => row?.amount, sortable: true },
    { name: "Transaction", selector: (row) => row?.transaction, sortable: true },
    { name: "Payed", selector: (row) => (row?.payed ? "Yes" : "No"), sortable: true },
    { name: "Payment Confirmed", selector: (row) => (row?.paymantConfirmed ? "Yes" : "No"), sortable: true },
    { name: "Accepted", selector: (row) => (row?.accepted ? "Yes" : "No"), sortable: true },
    { name: "Point", selector: (row) => row?.point, sortable: true },
    { name: "Sektor", selector: (row) => row?.sector, sortable: true },
    {
      name: "Speciality Probabilities",
      selector: (row) =>
        row?.specialityProbabilities.map((sp) => sp.speciality.name).join(", "),
      sortable: true,
    },
    { name: "Qrup", selector: (row) => row?.group.name, sortable: true },
    { name: "İstifadəçi", selector: (row) => row?.user?.fullName, sortable: true },
    { name: "Ehtimal", selector: (row) => row?.probability, sortable: true },
    { name: "Yaranma tarixi", selector: (row) => row?.createDate, sortable: true },
  ];

  return (
    <div>
      <DataTable columns={columns} data={applications} pagination />
    </div>
  );
};

export default Applications;
