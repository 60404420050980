import React, { useEffect, useMemo, useState } from "react";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Input from "../Input";
import SelectList from "../SelectList";
import {
  educationForm,
  educationMethod,
  languageCode,
  vacancyType,
} from "../../constants";
import SpecialityFilter from "./SpecialityFilter";
import {
  createSpeciality,
  deleteSpeciality,
  getGroups,
  getSpecialitiesByUniversity,
  getUniversities,
  updateSpeciality,
} from "../../store/actions/adminActions";
import { IFormData, IGroup, ISpeciality, IUniversity } from "../../types/type";
import { getLocalStorage, setLocalStorage } from "../../constants/localstorage";
import { verifyToken } from "../../store/actions/authActions";
import { Navigate } from "react-router-dom";
import DataTable, { TableColumn } from "react-data-table-component";
import Pen from "../../assets/images/pen.png";
import Trash from "../../assets/images/trash.png";
import Add from "../../assets/images/add.png";
import Close from "../../assets/images/close.png";
import { Button, Form, Modal } from "react-bootstrap";

const defaultValue: IFormData = {
  name: "",
  code: "",
  totalPlaces: 0,
  paidPlace: 0,
  unPaidPlace: 0,
  sector: "",
  subGroup: "",
  price: 0,
  isRemote: false,
  languageCode: "",
  translationName: "",
  university: "",
  group: "",
  passPoint: 0,
};

const Speciality: React.FC = () => {
  const [universityData, setUniversityData] = useState<IUniversity[]>([]);
  const [specialityData, setSpecialityData] = useState<ISpeciality[]>([]);
  const [editId, setEditId] = useState<string | null>(null);
  const [groupData, setGroupData] = useState<IGroup[]>([]);
  const [formData, setFormData] = useState<IFormData>(defaultValue);

  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const token = getLocalStorage("accessToken");
  const disabled = useMemo(() => !formData?.name, [formData]);

  const [showModal, setShowModal] = useState(false);

  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [deleteId, setDeleteId] = useState<string | null>(null);

  useEffect(() => {
    if (token) {
      verifyToken()
        .then((isTokenValid) => {
          setIsLoggedIn(isTokenValid);
          setLocalStorage("isLoggedIn", isTokenValid);
          fetchUniversities();
          fetchGroups();
        })
        .catch((error) => {
          console.error("Token verification failed", error);
          setIsLoggedIn(false);
          setLocalStorage("isLoggedIn", "false");
        });
    } else {
      setIsLoggedIn(false);
      setLocalStorage("isLoggedIn", "false");
    }
  }, [token]);

  const localIsLogedIn = getLocalStorage("isLoggedIn");
  if (!localIsLogedIn || !token) return <Navigate to="/login" />;

  const handleFilterChange = (filteredData: ISpeciality[]) => {
    setSpecialityData(filteredData);
  };

  const fetchSpecialities = async (universityId: string) => {
    try {
      const specialities = await getSpecialitiesByUniversity(universityId);
      if (specialities) {
        setSpecialityData(specialities);
      }
    } catch (error) {
      toast.error("Failed to fetch specialities");
      console.log(error);
    }
  };

  const fetchGroups = async () => {
    try {
      const groups = await getGroups();
      if (groups) {
        setGroupData(groups);
      }
    } catch (error) {
      toast.error("Failed to fetch groups");
      console.log(error);
    }
  };

  const fetchUniversities = async () => {
    try {
      const universities = await getUniversities();
      if (universities) {
        setUniversityData(universities);
      }
    } catch (error) {
      toast.error("Failed to fetch universities");
      console.log(error);
    }
  };

  const addSpeciality = async () => {
    try {
      const selectedUniversity = universityData.find(
        (item) => item.name === formData?.university
      )!;
      const selectedGroup = groupData.find(
        (item) => item.name === formData?.group
      )!;
      const newSpeciality = {
        name: formData?.name,
        code: formData?.code,
        totalPlace: formData?.totalPlaces,
        paidPlaces: formData?.paidPlace,
        unPaidPlaces: formData?.unPaidPlace,
        sector: formData?.sector === "" ? "RUS_AZERBAIJANI" : formData?.sector,
        subGroup: formData?.subGroup === "" ? null : formData?.subGroup,
        price: formData?.price,
        isRemote: formData?.isRemote,
        translations: [
          {
            name: formData?.translationName,
            languageCode:
              formData?.languageCode === "" || null
                ? "ru"
                : formData?.languageCode,
          },
        ],
        university: selectedUniversity,
        group: selectedGroup,
        passPoint: formData?.passPoint,
        emptyPaidPlaces: formData?.paidPlace,
        emptyUnPaidPlaces: formData?.unPaidPlace,
        emptyPlace: formData?.totalPlaces,
        fullPlace: 0,
        fullPaidPlaces: 0,
        fullUnPaidPlaces: 0,
        remote: formData?.isRemote,
      };
      const response = await createSpeciality(newSpeciality);
      if (response) {
        fetchSpecialities(newSpeciality?.university?.id);
        setFormData(defaultValue);
        toast.success("Speciality added successfully");
        setShowModal(false); // Close modal after adding
      } else {
        toast.error("Failed to create speciality");
      }
    } catch (error) {
      toast.error("Failed to add speciality");
      console.log(error);
    }
  };

  const editSpeciality = async () => {
    try {
      const editedSpeciality = {
        id: editId!.toString(),
        name: formData?.name,
        code: formData?.code,
        totalPlace: formData?.totalPlaces,
        paidPlaces: formData?.paidPlace,
        unPaidPlaces: formData?.unPaidPlace,
        sector:
          formData?.sector === "az"
            ? "AZERBAIJANI"
            : formData?.sector === "rus"
            ? "RUS"
            : "RUS_AZERBAIJANI",
        subGroup:
          formData?.subGroup === "" || formData?.subGroup === "ümumi"
            ? null
            : formData?.subGroup,
        price: formData?.price,
        isRemote: formData?.isRemote,
        translations: [
          {
            name: formData?.translationName,
            languageCode: "ru",
          },
        ],
        university: universityData.find(
          (item) => item.name === formData?.university
        )!,
        group: groupData.find((item) => item.name === formData?.group)!,
        passPoint: formData?.passPoint,
      };
      const response = await updateSpeciality(editedSpeciality);
      if (response) {
        fetchSpecialities(editedSpeciality?.university?.id);
        setEditId(null);
        setFormData(defaultValue);
        toast.success("Speciality updated successfully");
        setShowModal(false); // Close modal after editing
      }
    } catch (error) {
      toast.error("Failed to update speciality");
      console.log(error);
    }
  };

  const handleSpecialityEdit = async (id: string) => {
    try {
      const speciality = specialityData.find((item) => item.id === id);
      if (speciality) {
        setEditId(id);
        setFormData((prev) => ({
          ...prev,
          name: speciality?.name,
          code: speciality?.code,
          totalPlaces: speciality?.totalPlace,
          paidPlace: speciality?.paidPlaces,
          unPaidPlace: speciality?.unPaidPlaces,
          sector: speciality?.sector,
          subGroup: speciality?.subGroup,
          price: speciality?.price,
          isRemote: speciality?.isRemote,
          languageCode: speciality?.translations[0]?.languageCode,
          translationName: speciality?.translations[0]?.name,
          university: speciality?.university?.name,
          group: speciality?.group?.name,
          passPoint: speciality?.passPoint,
        }));
        setShowModal(true);
      }
    } catch (error) {
      toast.error("Failed to load speciality data for editing");
      console.log(error);
    }
  };

  const confirmDelete = (id: string) => {
    setDeleteId(id);
    setShowDeleteModal(true);
  };

  const handleSpecialityDelete = async () => {
    if (deleteId) {
      const speciality = specialityData.find((item) => item.id === deleteId);
      const universityId = speciality?.university?.id;
      try {
        const response = await deleteSpeciality(deleteId);
        if (response) {
          fetchSpecialities(universityId!);
          toast.success("Speciality deleted successfully");
          setShowDeleteModal(false);
          setDeleteId(null);
        }
      } catch (error) {
        toast.error("Failed to delete speciality");
        console.log(error);
      }
    }
  };

  const columns: TableColumn<ISpeciality>[] = [
    { name: "ID", selector: (row) => row?.id, sortable: true },
    { name: "İxtisas adı", selector: (row) => row?.name, sortable: true },
    { name: "İxtisas kodu", selector: (row) => row?.code, sortable: true },
    { name: "Boş yerlər", selector: (row) => row?.emptyPlace, sortable: true },
    { name: "Dolu yerlər", selector: (row) => row?.fullPlace, sortable: true },
    {
      name: "Toplam yerlər",
      selector: (row) => row?.totalPlace,
      sortable: true,
    },
    { name: "Ödənişli yer", selector: (row) => row?.paidPlaces, sortable: true },
    {
      name: "Ödənişsiz yer",
      selector: (row) => row?.unPaidPlaces,
      sortable: true,
    },
    {
      name: "Boş ödənişli yerlər",
      selector: (row) => row?.emptyPaidPlaces,
      sortable: true,
    },
    {
      name: "Dolu ödənişli yerlər",
      selector: (row) => row?.fullPaidPlaces,
      sortable: true,
    },
    {
      name: "Boş ödənişsiz yerlər",
      selector: (row) => row?.emptyUnPaidPlaces,
      sortable: true,
    },
    {
      name: "Dolu ödənişsiz yerlər",
      selector: (row) => row?.fullUnPaidPlaces,
      sortable: true,
    },
    { name: "Sektor", selector: (row) => row?.sector, sortable: true },
    { name: "Alt qrup", selector: (row) => row?.subGroup, sortable: true },
    { name: "Təhsil haqqı", selector: (row) => row?.price, sortable: true },
    { name: "", selector: (row) => "" },
    {
      name: "Ixtisasın tərcüməsi",
      selector: (row) => row?.translations[0]?.name,
      sortable: true,
    },
    {
      name: "Universitet",
      selector: (row) => row?.university?.name,
      sortable: true,
    },
    { name: "Qrup", selector: (row) => row?.group?.name, sortable: true },
    { name: "Keçid balı", selector: (row) => row?.passPoint, sortable: true },
    {
      name: "Təhsil növü",
      selector: (row) => (row?.isRemote ? "Qiyabi" : "Əyani"),
      sortable: true,
    },
    {
      name: "Əməliyyatlar",
      cell: (row) => (
        <div className="flex justify-center">
          <Button
            onClick={() => handleSpecialityEdit(row?.id)}
            className="w-16 h-12 rounded-1 bg-cyan flex items-center justify-center ml-3"
          >
            <img width={20} height={20} src={Pen} alt="edit" />
          </Button>
          <Button
            onClick={() => confirmDelete(row?.id)}
            className="w-16 h-12 rounded-1 bg-red flex items-center justify-center ml-3"
          >
            <img src={Trash} alt="delete" />
          </Button>
        </div>
      ),
    },
  ];

  return (
    <div>
      <ToastContainer />
      <Button
        variant="success"
        onClick={() => {
          setFormData(defaultValue);
          setEditId(null);
          setShowModal(true);
        }}
        className="w-16 h-12 rounded-1 bg-green flex items-center justify-center ml-3"
      >
        <img src={Add} alt="add" />
      </Button>
      <SpecialityFilter onFilterChange={handleFilterChange} />
      <DataTable columns={columns} data={specialityData} pagination />

      <Modal
        show={showModal}
        onHide={() => setShowModal(false)}
        size="lg"
        centered
        className="fixed inset-0 z-50 flex items-center justify-center mt-5"
      >
        <div className="bg-white rounded-lg shadow-lg max-w-lg w-full mx-auto my-auto relative">
          <button
            type="button"
            className="absolute top-2 right-2 text-black text-lg font-bold"
            onClick={() => setShowModal(false)}
          >
            <img src={Close} alt="close" />
          </button>
          <div className="bg-blue-500 text-white px-4 py-2 flex justify-between items-center rounded-t-lg">
            <h2 className="font-semibold text-lg">
              {editId ? "Redaktə et" : "Əlavə et"}
            </h2>
          </div>
          <div className="p-4 overflow-y-auto max-h-[70vh]">
            <Form>
              <div className="mb-4">
                <Input
                  label="İxtisas adı"
                  value={formData?.name}
                  className="block w-full"
                  inputClassName="border rounded px-2 py-1 w-full"
                  onChange={(e) => {
                    setFormData((prev) => ({
                      ...prev,
                      name: e.target.value,
                    }));
                  }}
                />
              </div>
              <div className="mb-4">
                <Input
                  label="İxtisas kodu"
                  value={formData?.code}
                  className="block w-full"
                  inputClassName="border rounded px-2 py-1 w-full"
                  onChange={(e) => {
                    setFormData((prev) => ({
                      ...prev,
                      code: e.target.value,
                    }));
                  }}
                />
              </div>
              <div className="mb-4">
                <Input
                  label="Boş yerlər"
                  value={formData?.totalPlaces}
                  className="block w-full"
                  inputClassName="border rounded px-2 py-1 w-full"
                  type="number"
                  onChange={(e) => {
                    setFormData((prev) => ({
                      ...prev,
                      totalPlaces: Number(e.target.value),
                    }));
                  }}
                />
              </div>
              <div className="mb-4">
                <Input
                  label="Ödənişli yer"
                  value={formData?.paidPlace}
                  type="number"
                  className="block w-full"
                  inputClassName="border rounded px-2 py-1 w-full"
                  onChange={(e) => {
                    setFormData((prev) => ({
                      ...prev,
                      paidPlace: Number(e.target.value),
                    }));
                  }}
                />
              </div>
              <div className="mb-4">
                <Input
                  label="Ödənişsiz yer"
                  value={formData?.unPaidPlace}
                  className="block w-full"
                  inputClassName="border rounded px-2 py-1 w-full"
                  type="number"
                  onChange={(e) => {
                    setFormData((prev) => ({
                      ...prev,
                      unPaidPlace: Number(e.target.value),
                    }));
                  }}
                />
              </div>
              <div className="mb-4">
                <SelectList
                  label="Sektor"
                  value={
                    formData?.sector === "AZERBAIJANI"
                      ? "az"
                      : formData?.sector === "RUS"
                      ? "rus"
                      : "ümumi"
                  }
                  className="block w-full"
                  onChange={(e) => {
                    setFormData((prev) => ({
                      ...prev,
                      sector:
                        e.target.value === "az"
                          ? "AZERBAIJANI"
                          : e.target.value === "rus"
                          ? "RUS"
                          : "RUS_AZERBAIJANI",
                    }));
                  }}
                  options={vacancyType}
                />
              </div>
              <div className="mb-4">
                <SelectList
                  label="Alt qrup"
                  value={formData?.subGroup}
                  className="block w-full"
                  onChange={(e) => {
                    setFormData((prev) => ({
                      ...prev,
                      subGroup: e.target.value === "" ? null : e.target.value,
                    }));
                  }}
                  options={educationForm}
                />
              </div>
              <div className="mb-4">
                <Input
                  label="Təhsil haqqı"
                  value={formData?.price}
                  className="block w-full"
                  inputClassName="border rounded px-2 py-1 w-full"
                  onChange={(e) => {
                    setFormData((prev) => ({
                      ...prev,
                      price: Number(e.target.value),
                    }));
                  }}
                />
              </div>
              <div className="mb-4">
                <SelectList
                  label="Tərcümənin dili"
                  value={formData?.languageCode}
                  className="block w-full"
                  onChange={(e) => {
                    setFormData((prev) => ({
                      ...prev,
                      languageCode: e.target.value,
                    }));
                  }}
                  options={languageCode}
                />
              </div>
              <div className="mb-4">
                <Input
                  label="Tərcümə"
                  value={formData?.translationName}
                  className="block w-full"
                  inputClassName="border rounded px-2 py-1 w-full"
                  onChange={(e) => {
                    setFormData((prev) => ({
                      ...prev,
                      translationName: e.target.value,
                    }));
                  }}
                />
              </div>
              <div className="mb-4">
                <SelectList
                  label="Universitet"
                  value={formData?.university}
                  className="block w-full"
                  onChange={(e) => {
                    setFormData((prev) => ({
                      ...prev,
                      university: e.target.value,
                    }));
                  }}
                  options={universityData}
                />
              </div>
              <div className="mb-4">
                <SelectList
                  label="Qrup"
                  value={formData?.group}
                  className="block w-full"
                  onChange={(e) => {
                    setFormData((prev) => ({
                      ...prev,
                      group: e.target.value,
                    }));
                  }}
                  options={groupData}
                />
              </div>
              <div className="mb-4">
                <Input
                  label="Keçid balı"
                  value={formData?.passPoint}
                  className="block w-full"
                  inputClassName="border rounded px-2 py-1 w-full"
                  onChange={(e) => {
                    setFormData((prev) => ({
                      ...prev,
                      passPoint: Number(e.target.value),
                    }));
                  }}
                />
              </div>
              <div className="mb-4">
                <SelectList
                  label="Təhsil növü"
                  value={formData?.isRemote ? "Qiyabi" : "Əyani"}
                  className="block w-full"
                  onChange={(e) => {
                    setFormData((prev) => ({
                      ...prev,
                      isRemote: e.target.value === "Qiyabi" ? true : false,
                    }));
                  }}
                  options={educationMethod}
                />
              </div>
            </Form>
          </div>
          <div className="bg-gray-100 px-4 py-2 flex justify-end rounded-b-lg">
            <Button
              variant="secondary"
              onClick={() => setShowModal(false)}
              className="bg-red text-white px-4 py-2 rounded mr-2"
            >
              Bağla
            </Button>
            <Button
              variant="primary"
              onClick={editId ? editSpeciality : addSpeciality}
              disabled={disabled}
              className="bg-cyan text-white px-4 py-2 rounded"
            >
              {editId ? "Redaktə et" : "Əlavə et"}
            </Button>
          </div>
        </div>
      </Modal>
      <Modal
        show={showDeleteModal}
        onHide={() => setShowDeleteModal(false)}
        size="sm"
        centered
        className="fixed inset-0 z-50 flex items-center justify-center mt-10"
      >
        <div className="bg-white rounded-lg shadow-lg max-w-sm w-full mx-auto my-auto relative">
          <div className="p-4">
            <h2 className="font-semibold text-lg">Əminsinizmi?</h2>
            <p>Bu universiteti silmək istəyirsinizmi?</p>
          </div>
          <div className="bg-gray-100 px-4 py-2 flex justify-end rounded-b-lg">
            <Button
              variant="secondary"
              onClick={() => setShowDeleteModal(false)}
              className="bg-cyan text-white px-4 py-2 rounded mr-2"
            >
              Bağla
            </Button>
            <Button
              variant="danger"
              onClick={handleSpecialityDelete}
              className="bg-red text-white px-4 py-2 rounded"
            >
              Sil
            </Button>
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default Speciality;
