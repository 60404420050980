import { useLocation, useNavigate } from "react-router-dom";
import Speciality from "../components/Dashboard/Speciality";
import University from "../components/Dashboard/University";
import { MenuNames } from "../types/type";
import Applications from "../components/Dashboard/Applications";
import Users from "../components/Dashboard/Users";
import { getLocalStorage, removeLocalStorage, setLocalStorage } from "../constants/localstorage";
import { logout } from "../store/actions/authActions";

const Dashboard = () => {
  let { pathname } = useLocation();
  let path = pathname.replace("/", "");
  let menuName = MenuNames[path as keyof typeof MenuNames];
  const navigate = useNavigate();

  const handleLogout = async () => {
    await logout();
    setLocalStorage("isLoggedIn", "false");
    removeLocalStorage("accessToken");
    removeLocalStorage("refreshToken");
    navigate("/login");
  };
  return (
    <>
      <div>
        <div className="flex justify-between items-center mb-4">
          <p className="text-h2 ">{menuName}</p>
          <button
            onClick={handleLogout}
            className="bg-red text-white px-4 py-2 rounded"
          >
            Logout
          </button>
        </div>
        {path === "speciality" ? (
          <Speciality />
        ) : path === "listofusers" ? (
          <Users />
        ) : path === "applications" ? (
          <Applications />
        ) : (
          <University />
        )}
      </div>
    </>
  );
};

export default Dashboard;
