import React, { SelectHTMLAttributes, useEffect } from "react";
import ArrowDown from "../assets/images/arrowDown.svg";
import { IGroup, ISpeciality, IUniversity } from "../types/type";

interface SelectListProps extends SelectHTMLAttributes<HTMLSelectElement> {
  label?: string;
  options: IUniversity[] | IGroup[] | ISpeciality[] | any[];
  value?: any;
  disabled?: boolean;
  openModal?: () => void;
  error?: string | boolean;
}

const SelectList: React.FC<SelectListProps> = ({
  label,
  options,
  className,
  disabled,
  openModal,
  error,
  value,
  ...props
}) => {
  return (
    <div className={`relative ${className || ""}`} onClick={openModal}>
      {label && (
        <label className="block text-h4Bold mb-2" htmlFor={props.id}>
          {label}
        </label>
      )}
      {!disabled ? (
        <select
          className="h-12 border border-0.5 border-lightGray2 rounded-2 w-full px-4 appearance-none"
          value={value}
          {...props}
        >
          <option value="" disabled>
            Select option
          </option>
          {options.map((option) => (
            <option data-id={option.id} key={option.id} value={option.name}>
              {option.name}
            </option>
          ))}
        </select>
      ) : (
        <>
          <input
            className={`h-12 border border-0.5 border-lightGray2 rounded-2 w-full px-4 appearance-none ${
              disabled ? "bg-transparent" : ""
            }`}
            type="text"
            value={props.defaultValue}
            disabled
          />
          {error && (
            <div className="absolute right-2 mt-1">
              <p className="text-h9 text-red">{error}</p>
            </div>
          )}
        </>
      )}
      <div className="absolute bottom-3 right-2 flex items-center px-2">
        <img src={ArrowDown} className="w-6 h-6" />
      </div>
    </div>
  );
};

export default SelectList;
