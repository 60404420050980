export const documentStatus = ["Yeni", "İcratda", "Arxiv"];
export const educationForm = [
  { id: 1, name: "ümumi" },
  { id: 2, name: "RK" },
  { id: 3, name: "Rİ" },
  { id: 4, name: "DT" },
  { id: 5, name: "TC" },
];

export const educationMethod = [
  { id: 1, name: "Əyani" },
  { id: 2, name: "Qiyabi" },
];
export const vacancyType = [
  { id: 1, name: "az" },
  { id: 2, name: "rus" },
  { id: 3, name: "ümumi" },
];

export const languageCode = [
  { id: 1, name: "ru" },
];

export const canChangePoint = [
  { id: "1", name: "Hə" },
  { id: "2", name: "Yox" },
];