import React from 'react';

const PhoneNumber = ({
  onChange,
  value,
}: {
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  value: string;
}) => {
  const formatPhoneNumber = (input: string) => {
    const cleared = input.replace(/\D/g, "").slice(0, 9);
    return cleared.replace(/^(\d{2})(\d{0,3})(\d{0,2})(\d{0,2})/, (_, p1, p2, p3, p4) =>
      [p1, p2, p3, p4].filter(Boolean).join(" ")
    );
  };

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const formattedValue = formatPhoneNumber(event.target.value);
    onChange({ ...event, target: { ...event.target, value: formattedValue } });
  };

  return (
    <div className="mt-2 h-12 flex items-center rounded-md shadow-sm relative px-2 bg-white w-full">
      <span className="text-black">+994</span>
      <input
        className="text-black bg-transparent ml-2 border-none outline-none !w-full"
        value={value}
        onChange={handleChange}
        type="text"
        placeholder="51 511 11 11"
      />
    </div>
  );
};

export default PhoneNumber;